<template>
    <div>
        <form-input :autoTranslate="false" label="Your City" placeholder="Papeete, etc"></form-input>
        <div class="uk-placeholder uk-text-center uk-flex uk-flex-middle uk-flex-center" style="height:  300px; background: white;">
            MAP PLACEHOLDER
        </div>
        <div class="selection uk-flex uk-flex-between uk-flex-middle">

            <strong >So and So, 16 Rue Mgr Tepano Jaussen, Papeete 98714, French Polynesia</strong>
            <form-button icon="check" class=""
            @click="pointSelected('TODO')">Confirm</form-button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            modelValue    : {},
        },
        data: function () {
            return {};
        },
        emits: ['update:modelValue'],
        computed: {
            value: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
        },
        methods: {
            pointSelected(point) {
                this.$emit('shippingData:complete', null);
                this.value = point;
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
