<template>
    <div class="checkout-external uk-margin-large-bottom">

        <div class="checkout-wrapper uk-container uk-container-xlarge uk-flex uk-padding" v-if=" ! checkoutComplete">

            <div class="main-checkout uk-width-expand uk-margin-right uk-animation-fade" >
                <!-- checkout progress -->
                <!--
                <div class="checkout-progress uk-padding-remove-vertical uk-container-xlarge uk-margin-auto ">
                    <div class="checkout-activationSteps uk-padding-small  uk-background-default  uk-margin-small-bottom uk-box-shadow-medium">
                        <ul class="uk-tab uk-subnav uk-subnav-pill uk-activationSteps uk-flex  uk-margin-remove-bottom " >
                            <li class=" uk-width-expand"
                                :class="{'uk-step-complete' : addressesSelected, 'uk-step-current': step == 0, 'uk-active': step == 0}"
                                @click.prevent="step = 0">
                                <a href="" @click.prevent="">
                                    <span uk-icon="check" v-if="addressesSelected"></span>
                                    <span uk-icon="user" v-if=" ! addressesSelected"></span>
                                    <span>Details & Address</span>
                                </a>
                            </li>
                            <li class="uk-events-none">
                                <span class="uk-text-secondary" uk-icon="arrow-right"></span>
                            </li>
                            <li class=" uk-width-expand"
                                :class="{'uk-active': step == 1}"
                                @click.prevent="attemptGoToStepOne()">
                                <a href="" @click.prevent="">
                                    <span uk-icon="cart"></span>
                                    <span>Shipping & Payment</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
-->
                <div class="checkout-steps" v-if=" ! addingNewAddress">
                    <!-- checkout step details -->
                    <div class="checkout-step-details uk-margin-top uk-animation-fade" v-show="step == 0">

                        <!-- login splash -->
                        <div class="details-splash uk-flex uk-animation-fade" v-if="stepOneMode == 'splash'">
                            <div class="splash-title uk-background-inverse">
                                YOUR DETAILS
                            </div>
                            <div class="splash-side uk-padding uk-width-1-2">
                                <h4>Connect to My Account</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam necessitatibus
                                    sit veritatis voluptatum. Amet ex ipsam officia, sequi velit voluptates voluptatum?
                                    Atque autem cum dolorum facilis laborum magni repudiandae voluptatibus.</p>
                                <span>Atque autem cum dolorum facilis laborum magni repudia</span>
                               <div class="button-wrapper uk-flex uk-flex-center uk-margin-top">
                                   <form-button type="primary" size="large" class="uk-text-large" @click="stepOneMode = 'login'">LOG IN</form-button>
                               </div>
                            </div>
                            <div class="splash-side splash-side--opposite splash-side--primary uk-padding uk-width-1-2 ">
                                <h4>I Do Not Have an Account</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laboriosam necessitatibus
                                    sit veritatis voluptatum. Amet ex ipsam officia, sequi velit voluptates voluptatum?
                                    Atque autem cum dolorum facilis laborum magni repudiandae voluptatibus.</p>
                                <span>Atque autem cum dolorum facilis laborum magni repudia</span>
                                <div class="button-wrapper uk-flex uk-flex-center uk-margin-top">
                                    <form-button type="secondary" size="large" class="uk-text-large" @click="stepOneMode = 'register'">REGISTER</form-button>
                                </div>
                            </div>
                        </div>

                        <!-- login ui -->
                        <div class="login-card uk-card uk-card-default" v-if="stepOneMode == 'login'">
                            <div class="uk-card-body uk-padding-small">

                                <div class="login-suggest-wrap" >
                                    <a class="title-link" @click="stepOneMode = 'splash'" >
                                        <span uk-icon="icon: arrow-left" ></span><span>Back</span></a>
                                    <h3 class="uk-margin-top">
                                        <icon-text icon="icon:user; ratio:2"
                                                   class="uk-flex uk-flex-bottom">Log into Your Account</icon-text></h3>

                                    <base-form
                                            action="/session"
                                            method="post"
                                            class="uk-margin-remove uk-animation-slide-bottom-small"
                                            :field-wrapper-margin="false"
                                            formPath="form/user-login"
                                            :expectedFields="3"
                                            @form:submitSuccess="demoLogin()"
                                    ></base-form>
                                </div>
                            </div>

                        </div>

                        <!-- register ui -->
                        <div class="uk-card uk-card-default uk-margin-top"
                             ref="billingAddressForm"
                             v-if="stepOneMode == 'register'">
                            <div class="uk-card-body uk-padding-small">
                                <div class="billing-address-wrapper">
                                    <a class="title-link" @click="stepOneMode = 'splash'" >
                                        <span uk-icon="icon: arrow-left" ></span><span>Back</span></a>

                                    <h3 class="uk-margin-top"><icon-text icon="icon:pencil; ratio:2;" >Register - Your Details & Billing Address</icon-text></h3>
                                    <base-form

                                            action="/test"
                                            method="post"
                                            class="uk-margin-remove uk-animation-slide-bottom-small"
                                            :field-wrapper-margin="false"
                                            formPath="form/ecommerce-addressAndRegister"
                                            :expectedFields="3"
                                            @form:submitSuccess="demoNewUserAddress()"
                                    ></base-form>

                                </div>
                            </div>
                        </div>
                        <div class="details-content uk-animation-fade">






                            <div class="uk-card uk-card-default uk-margin-top" v-if="showBillingAddressForm && !sameShippingAddressNewUser">
                                <div class="uk-card-body uk-padding-small">
                                    <div class="billing-address-wrapper">
                                        <h3>Shipping address</h3>
                                        <base-form

                                                action="/test"
                                                method="post"
                                                class="uk-margin-remove uk-animation-slide-bottom-small"
                                                :field-wrapper-margin="false"
                                                formPath="form/ecommerce-address"
                                                :expectedFields="3"
                                                @form:submitSuccess="demoLogin()"
                                        ></base-form>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                    <!-- checkout step shipping & payment -->
                    <div class="checkout-step-shipping-payment uk-margin-top uk-animation-fade" v-show="step == 1">
                        <h2 class="uk-text-center">Your Shipping & Payment</h2>
                        <div class="checkout-step-items">

                            <div class="billing-address checkout-sub-step uk-background-muted  uk-padding-small sub-step   uk-border-radius-small"
                                 :class="{'complete': addressesSelected}">
                                <h4 class="uk-margin-remove uk-flex ">
                                    <span uk-icon="icon:check;ratio:2" class="sub-step-success-icon uk-text-success"></span>
                                    <icon-text icon="home">Billing Address</icon-text>
                                    <form-button class="checkout-sub-step-edit"
                                                 type="secondary"
                                                 icon="pencil"
                                                 @click="addressesSelected = false">Change</form-button>
                                </h4>
                                <div class="sub-step-summary">
                                    <div><strong>Billing Address: </strong><span>Avichay Mozes apt 17 22nd 3922 st Pape'ete 6766339, French Polynesia</span></div>
                                </div>
                                <div class="sub-step-content uk-animation-fade" v-show="! addressesSelected">
                                    <p class="uk-margin-remove-top">Please select your billing address</p>

                                    <div class="uk-flex uk-flex-bottom" v-if=" !billingAddressAddingNew">
                                        <form-input type="select"
                                                    label="Your Billing Address"
                                                    :auto-translate="false"
                                                    :null-option="{value: null, disabled: true, label: 'Select one of your addresses or add a new one'}"
                                                    v-model="billingAddress"
                                                    :nullOptionSelectable="false"
                                                    class="uk-margin-remove-top uk-margin-remove-bottom uk-margin-small-right"
                                                    :list="addressList"></form-input>

                                        <form-button type="primary"
                                                     @click="addressesSelected = true"
                                                     class="uk-margin-small-right">Select</form-button>
                                        <form-button type="secondary"
                                                     @click="billingAddressAddingNew = true"
                                                     class="">Add an Address</form-button>
                                    </div>

                                    <div class="create-new uk-animation-fade" v-show="billingAddressAddingNew">

                                        <a class="title-link uk-margin-bottom-small" @click="billingAddressAddingNew = false" >
                                            <span uk-icon="icon: arrow-left" ></span><span>Select from existing addresses</span></a>
                                        <h5 class="uk-margin-remove-top">Please add your address. You can go back to select an existing address</h5>
                                        <base-form
                                                action="/test/fast"
                                                method="post"
                                                class="uk-margin-remove uk-animation-slide-bottom-small"
                                                :field-wrapper-margin="false"
                                                formPath="form/ecommerce-address"
                                                :expectedFields="3"
                                                @form:submitSuccess="billingAddressAddedFromStepTwoDemo()"
                                        ></base-form>
                                    </div>



                                </div>
                            </div>

                            <div class="billing-cycle checkout-sub-step uk-background-muted  uk-padding-small sub-step uk-border-radius-small"
                                 :class="{'complete': billingCycleComplete}">
                                <h4 class="uk-margin-remove uk-flex ">
                                    <span uk-icon="icon:check;ratio:2" class="sub-step-success-icon uk-text-success"></span>
                                    <icon-text icon="reload">Billing Cycle</icon-text>
                                    <form-button class="checkout-sub-step-edit"
                                                 type="secondary"
                                                 icon="pencil"
                                                 @click="billingCycleComplete = false">Change</form-button>
                                </h4>
                                <div class="sub-step-summary">
                                    <div v-if="billingCycleComplete"><strong>Monthly bill on: </strong><span>{{billingCycleObject.label}}</span></div>
                                </div>
                                <div class="sub-step-content uk-animation-fade" v-show="! billingCycleComplete">
                                    <p class="uk-margin-remove-top">Please select billing cycle</p>
                                    <form-input
                                            type="radioOptionBoxes"
                                            :label="false"
                                            :auto-translate="false"
                                            :list="billingCycleOption"
                                            v-model="billingCycle"></form-input>
                                    <form-button type="primary" @click="billingCycleComplete = true" icon="check">Confirm</form-button>
                                </div>
                            </div>

                            <div class="shipping checkout-sub-step uk-background-muted  uk-padding-small sub-step   uk-border-radius-small"
                                 :class="{'complete': shippingDataComplete}">
                                <h4 class="uk-margin-remove uk-flex ">
                                    <span uk-icon="icon:check;ratio:2" class="sub-step-success-icon uk-text-success"></span>
                                    <icon-text icon="cart">Shipping Method</icon-text>
                                    <form-button class="checkout-sub-step-edit"
                                                 type="secondary"
                                                 icon="pencil"
                                                 @click="shippingDataComplete = false">Change</form-button>
                                </h4>
                                <div class="sub-step-summary">
                                    <div v-if="selectedShippingMethod == 0 || selectedShippingMethod == 1"><strong>Ship To: </strong><span>Avichay Mozes apt 17 22nd 3922 st Pape'ete 6766339, French Polynesia</span></div>
                                    <div v-if="selectedShippingMethod == 2"><strong>Pickup At: </strong><span>Some shop -  22nd 3922 st Pape'ete 6766339, French Polynesia</span></div>
                                </div>
                                <div class="sub-step-content uk-animation-fade" v-show="! shippingDataComplete">
                                    <p class="uk-margin-remove-top">Please select your shipping method. Additional information may be required after the selection</p>
                                    <form-input
                                            type="radioOptionBoxes"
                                            :label="false"
                                            :auto-translate="false"
                                            :list="finalShippingOptions"
                                            v-model="selectedShippingMethod"></form-input>

                                    <component class="uk-animation-fade"
                                               @shippingData:complete="shippingDataComplete = true"
                                               v-if="shippingDataComponent"
                                               v-model="shippingAddress"
                                               :addressList="addressList"
                                               :is="shippingDataComponent"></component>
                                </div>
                            </div>


                            <div>
                                <div class="payment checkout-sub-step uk-background-muted  uk-padding-small sub-step   uk-border-radius-small">
                                    <h4 class="uk-margin-remove uk-flex ">
                                        <icon-text icon="credit-card">Finalization & Payment</icon-text>
                                    </h4>
                                    <div class="sub-step-content uk-animation-fade" >


                                        <div class="uk-panel uk-margin-top uk-background-inverse uk-padding-small ">
                                            <h3 class=" uk-margin-remove-bottom">Terms of Service & Documents</h3>
                                            <p class="uk-margin-remove-top">
                                                Please accept and review our
                                                <a href="#" @click.prevent="showDemoDocument">General Terms & Conditions</a>,
                                                and our
                                                <a href="#" @click.prevent="showDemoDocument">Other Document</a> i
                                                n order to finilize your order</p>
                                            <div class="checkboxes-wrapper" style="max-width: 500px;">
                                                <form-input
                                                        type="checkbox"
                                                        name="agree_tos"
                                                        :auto-translate="false"
                                                        v-model="agreeTos"
                                                        class="uk-margin-small-bottom"
                                                        label="I accept the terms and conditions"
                                                ></form-input>
                                                <form-input
                                                        type="checkbox"
                                                        name="aggree_bill"
                                                        :auto-translate="false"
                                                        v-model="agreeFirstBill"
                                                        class="uk-margin-small-bottom"
                                                        :html-label="true"
                                                        label="I acknowledge that the first monthly invoice will include charge for the usage between time of activation and the time the invoice is produced."
                                                ></form-input>
                                                <form-input
                                                        type="checkbox"
                                                        name="agree_marketing"
                                                        :auto-translate="false"
                                                        v-model="agreeMarketing"
                                                        class="uk-margin-small-bottom"
                                                        label="I agree to receive promotional emails"
                                                ></form-input>
                                                <p class="uk-margin-remove-bottom">Please provide <a href="#">[description of identification document]</a></p>
                                                <form-input type="AddFileButton" :autoInputStyling="false" labe="ID Document"></form-input>
                                            </div>
                                        </div>



                                        <div class="uk-padding-small uk-margin-top uk-panel uk-position-relative uk-background-inverse uk-padding-small uk-margin-bottom">
                                            <h3 class="">Please select your payment method</h3>
                                            <form-input
                                                    type="radioOptionBoxes"
                                                    :label="false"
                                                    :auto-translate="false"
                                                    :list="finalPaymentMethodOptions"
                                                    v-model="paymentMethod"></form-input>

                                            <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center uk-margin-bottom">
                                                <icon-text icon="icon:lock; ratio: 2.5" class="uk-flex uk-flex-top"><div style="display: block; text-align: left;">
                                                    Your payment information is encrypted.<br>
                                                    Your payment data is handled by dedicated high security servers</div></icon-text>
                                            </div>
                                           <form-button
                                                   v-show="isImmediatePayment"
                                                   class="uk-animation-slide-bottom"
                                                   icon="check"
                                                   type="primary"
                                                   @click="completeOrderDemo"
                                                   :fullWidth="true">{{finishOrderButtonText}}</form-button>
                                            <form-button
                                                    v-show=" ! isImmediatePayment"
                                                    class="uk-animation-slide-bottom"
                                                    icon-end="chevron-right"
                                                    type="primary"
                                                    @click="completeOrderDemo"
                                                    :fullWidth="true">{{finishOrderButtonText}}</form-button>
                                            <spinner

                                                    :show-spinner="false"
                                                    :show="! isFormComplete || ! agreeTos"
                                                    overlay="absolute"

                                            ><h3>{{paymentActivationConditionText}}</h3></spinner>
                                        </div>



                                    </div>


                                </div>

                                <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center uk-margin-bottom">
                                    <img src="/img/credit_card_logos.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <!-- todo: put in popup or something -->
                <div class="add-address uk-animation-slide-right-small" v-if=" addingNewAddress">

                    <div class="" >
                        <a class="title-link" @click="cancelAddingAddress" >
                            <span uk-icon="icon: arrow-left" ></span><span>Cancel</span></a>
                        <h3>Add a New Address</h3>
                        <base-form
                                action="/test/fast"
                                method="post"
                                class="uk-margin-remove uk-animation-slide-bottom-small"
                                :field-wrapper-margin="false"
                                formPath="form/ecommerce-address"
                                :expectedFields="3"
                                @form:submitSuccess="addAddressDemo()"
                        ></base-form>
                    </div>
                </div>

            </div>

            <div class="cart-wrapper">

                <div class="uk-card uk-card-default uk-margin-right">
                    <div class="uk-card-header">
                        <h3 class="uk-card-title">
                            Your Cart
                        </h3>
                    </div>
                    <div class="uk-card-body uk-padding-small">
                        <cart summary-position="bottom"></cart>
                        <div class="uk-text-success uk-text-center uk-margin-top uk-text-semibold uk-flex uk-flex-middle uk-flex-center">
                            <icon-text icon="lock">Secure Payment</icon-text>
                        </div>

                    </div>
                </div>
            </div>


            <div class="upsales-container  uk-animation-slide-bottom" v-show="showUpsaleOverlay">
                <div class="overlay-background" @click="showUpsaleOverlay = false"></div>
                <div class="wrapper uk-card uk-card-default">
                    <div class="inner uk-container uk-container-large uk-padding-small uk-text-center">
                        <div class="upsales-title"><h3>Last minute offers</h3></div>
                        <div class="upsales-content">
                            <product-upsale-carousel item-size="micro"></product-upsale-carousel>
                        </div>
                        <hr>
                        <div class="upsales-close">
                            <form-button
                                    @click="showUpsaleOverlay = false"
                                    icon-end="chevron-right">Close & Continue</form-button>
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="checkout-complete-wrapper uk-animation-fade  uk-margin-top" v-if="checkoutComplete">
            <checkout-complete></checkout-complete>
        </div>



    </div>

</template>

<script>


    // setup components for shipping
    import {reactive, nextTick, getCurrentInstance} from 'vue';

    import Cart from '@/client/components/ecommerce/Cart.vue'
    import CheckoutComplete from '@/client/components/ecommerce/CheckoutComplete.vue'
    import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'

    import ProductUpsale from "@/client/components/ecommerce/products/ProductUpsale.vue";
    import ProductUpsaleCarousel from "@/client/components/ecommerce/products/ProductUpsaleCarousel.vue";

    export default {
        setup () {
            let instance = getCurrentInstance();
          //  console.log(instance);

            let shippingComponents = {};

            // TODO: consolidate into composition - assign all components as sync or async from a folder, including app folder.
            // TODO: look in app, right now we take only from core, so we cant extend
            // find shipping method components, using webpack
            let context = require.context('@/client/components/ecommerce/shipping/shippingData/', true, /\.vue/);
            context.keys().forEach(key => {
                let name = key.replace('./', '').replace('.vue', '');
                let src  = 'components/ecommerce/shipping/shippingData/' + name + '.vue';
                shippingComponents[name] = asyncFactory(src);
            });




            instance.components = reactive({ProductUpsaleCarousel, CheckoutComplete, Cart, ...shippingComponents});
            return {};
        },
        props: {
            demoMode: {
                default: null
            }
        },
        data: function () {
            let shippingOptions = reactive([
                {
                    value: 0,
                    label: 'Regular Post - FREE',
                    description: 'Some description text. 3-5 business days.',
                    dataComponent: 'SelectAddress'
                },
                {
                    value: 1,
                    label: 'Delivery to the Door - 100 F',
                    description: 'Have everything delivered to your doorstep. 1-3 business days.',
                    dataComponent: 'SelectAddress'
                },
                {
                    value: 2,
                    label: 'Pickup at Store - FREE',
                    description: 'Pick your products up yourself, at a vendor location near you',

                    dataComponent: 'SelectPickup'
                },
            ]);


            return {
                identified: false,
                stepOneMode: 'splash', // 'login','register'
                showLogin: false,
                isLoggedIn : false,
                addressList: [
                    {
                        value: 0,
                        label: 'Avichay Mozes apt 17 "brosh" , 22nd 3922 st Tel Aviv 6766339, Israel'
                    },{
                        value: 1,
                        label: 'Someone else apt 24 "something" , 21st 4672 st Pape\'ete 6766339, Israel'
                    }
                ],
                step: 0,
                shippingAddress: 0,
                billingAddress: 0,
                addressesSelected: true,
                sameShippingAddress : true,
                sameShippingAddressNewUser: true,
                showBillingAddressForm: true,
                showShippingAddressForm: false,
                addingNewAddress: false,
                selectedShippingMethod: 0,
                shippingOptions : shippingOptions,
                shippingData: null,
                shippingDataComplete: false,
                billingCycleOption: [
                    {
                        value: 0,
                        label: '2nd of each month',
                        description: 'Default date - no additional charge'
                    },
                    {
                        value: 1,
                        label: '12nd of each month',
                        description: 'A custom date for your convenience. Additional charge of 20 F / MO'
                    }, {
                        value: 2,
                        label: '17th of each month',
                        description: 'A custom date for your convenience. Additional charge of 20 F / MO'
                    },
                    {
                        value: 3,
                        label: '28th of each month',
                        description: 'A custom date for your convenience. Additional charge of 20 F / MO'
                    },

                ],
                paymentMethodOptions: [
                    {
                        value: 0,
                        label: 'Credit card nr. **** **** **** 2134',
                        description: 'Your default means of payment'
                    },
                    {
                        value: 1,
                        label: 'Bank Account nr. *** *** *** 231',
                        description: 'Existing payment method in your account'
                    }, {
                        value: 3,
                        label: 'Credit Card',
                        description: 'Pay using a new credit card'
                    },
                    {
                        value: 4,
                        label: 'Pay when collecting',
                        description: 'Pay as you collect your products'
                    },

                ],
                billingCycle: 0, // default
                billingCycleComplete: true, // default true
                billingAddressAddingNew : false,
                agreeTos: false,
                agreeMarketing: false,
                agreeFirstBill : false,
                checkoutComplete: false,
                showUpsaleOverlay : false,
                paymentMethod: 0,
                isSuspicious : false,

            };
        },
        //compnents: {} // DO NOT ASSIGN HERE! Use Setup!!
        methods: {
            scrollToRef(refKey) {
                if ( utilities.isSSR()) {
                    return;
                }
                let el = this.$refs[refKey] || false;
                let isElementXPercentInViewport = (el, percentVisible) => {
                    let
                        rect = el.getBoundingClientRect(),
                        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

                    return !(
                        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
                        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
                    )
                };

                if (! el) {
                    return;
                }
                if ( isElementXPercentInViewport(el, 100)) {
                    return;
                }

                if (el) {
                    let offsetPosition =  el.getBoundingClientRect().top - 110;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });

                }
            },
            attemptGoToStepOne() {
                if ( ! this.addressesSelected) {
                    this.$s.ui.notification('Please complete the first step','danger');
                    return;
                }

                this.step = 1;
            },
            demoLogin() {
                this.$s.ui.notification('Logged in Successfully!', 'success');
                this.isLoggedIn              = true;
                this.showLogin               = false;
                this.addressesSelected       = true;
                this.showBillingAddressForm  = false;
                this.showShippingAddressForm = false;
                this.step = 1;
            },
            demoNewUserAddress () {
                this.$s.ui.notification('Welcome to Vodafone! Please continue', 'success');
                this.isLoggedIn              = true;
                this.showLogin               = false;
                this.addressesSelected       = true;
                this.showBillingAddressForm  = false;
                this.showShippingAddressForm = false;
                this.step = 1;

               nextTick(() => {
                   this.scrollToRef('stepOneLoggedInWrap');
               });
            },
            cancelAddingAddress () {
                //todo : clear form
                this.addingNewAddress = false;
            },
            addAddressDemo () {
                this.addressList.push( {
                    value: 2,
                    label: 'Someone else apt 16 "something" , 11st 4938 st Netanya 6766339, Israel'
                });

                this.shippingAddress  = 2;
                this.billingAddress   = 2;
                this.addingNewAddress = false;
                this.$s.ui.notification('Your address is added and is selected for shipping and delivery', 'success');
            },
            showDemoDocument () {
                let largeLorem ='\n' +
                    '\n' +
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices ultricies accumsan. Sed lobortis erat nulla, ultricies consequat neque volutpat non. Quisque interdum sapien metus, vel placerat nisi viverra in. Sed non tincidunt odio, nec interdum neque. Ut eu sagittis enim. Nulla suscipit elementum porttitor. Aenean congue cursus urna, ut mattis dui mollis id.\n' ;
                this.$s.ui.modal.alert(largeLorem, 'Terms & Conditions').then (() => {}, () => {});

                return '';
            },
            completeOrderDemo () {
                this.$s.ui.notification('DEMO: if required, user was redirected to payment and has returned to this page.');
                this.checkoutComplete = true;
            }
        },
        computed: {
            finalShippingOptions() {
              let result = [...this.shippingOptions];

                if (this.isSuspicious) {
                    result.splice(0,2);
                }

              return result;
            },
            shippingMethodObject() {
                return this.shippingOptions[this.selectedShippingMethod];
            },
            billingCycleObject() {
                return this.billingCycleOption[this.billingCycle];
            },
            paymentMethodObject() {
                return this.paymentMethodIotuibs[this.paymentMethod];
            },
            shippingMethodId() {
                return this.selectedShippingMethod;
            },
            shippingDataComponent() {
              let component =   this.shippingMethodObject.dataComponent || false;
              return component;
            },
            billingAddressText () {
                let result = '';

                return result;
            },
            billingAddressAddedFromStepTwoDemo() {
                this.billingAddressAddingNew = false;
                this.addressesSelected = true;
                this.$s.ui.notification('Your address is added and is selected as the billing address for this order', 'success');
            },
            isFormComplete () {
                return this.billingCycleComplete && this.shippingDataComplete && this.addressesSelected;
            },

            paymentActivationConditionText() {
                let conditions = [];

                if ( ! this.isFormComplete) {
                    conditions.push('complete the form above')
                }

                if ( ! this.agreeTos) {
                    conditions.push('agree to the terms and conditions')
                }

                if (conditions.count < 1) {
                    return null;
                }
                return 'Please ' + conditions.join(' & ') + ' to complete the order';

            },
            finalPaymentMethodOptions () {
                let methods = [...this.paymentMethodOptions];


                if (this.isSuspicious) {
                    methods.splice(0,3);
                    return reactive(methods);
                }
                if (this.selectedShippingMethod != 2) {
                    methods.splice(3,1);
                }
                return reactive(methods);
            },
            isImmediatePayment() {
                return this.paymentMethod != 3;
            },
            finishOrderButtonText() {
                if ( ! this.isImmediatePayment) {
                    return 'CONTINUE TO PAYMENT';
                }

                if (this.paymentMethod == 4) {
                    return 'PLACE ORDER'
                }

                return 'CONFIRM & PAY';
            },

        },
        created () {
            if (this.demoMode == 'suspicious') {
                this.isSuspicious = true;
                this.selectedShippingMethod = 2;
                this.paymentMethod = 4;
            }

        },
        mounted () {

        },
        watch : {
            shippingDataComplete () {
                this.$s.ui.notification('DEOM: the price of shipping will be added to the cart in the final system');
            }
        }

    }
</script>

<style scoped lang="scss">

    .checkout-steps  {
        border-radius: var(--border-radius-small);
    }

    .checkout-sub-step{
        margin-bottom: calc(var(--global-margin) * 1.5);

        .sub-step-success-icon {
            display: none;
        }

        .checkout-sub-step-edit {
            display: none;
            margin-inline-start: auto;
        }

        .sub-step-summary {
            display: none;
        }

        &.complete {
            border-left: 4px solid var(--global-success-background);
           // border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;

            [dir=rtl] & {
               // border-radius:  var(--border-radius-small) 0 0 var(--border-radius-small);
            }
            .sub-step-success-icon {
                display: inline-block;
            }

            .checkout-sub-step-edit {
                display: inline-block;
            }

            .sub-step-summary {
                display: block;
            }

        }
    }

    .payment-iframe-container{
        padding-top: calc(var(--global-margin) * 2);
        padding-bottom: calc(var(--global-margin) * 2);
    }

    .upsales-container {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 100%;
        z-index: 29;
        background: transparent;

        .overlay-background {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 100%;
            background: rgba(0,0,0,0.2);
            z-index: 30;
        }
        .wrapper {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
            height: 350px;
            background: var(--global-inverse-color);
            border-top: 3px solid var(--global-primary-background);
            z-index: 31;

        }
    }

    .details-splash {
        position: relative;
        .splash-title {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            min-width: 200px;
            font-size: calc(var(--global-font-size) * 1.3);
            font-weight: 600;
            color: var(--global-secondary-background);
            padding-top: calc(var(--global-margin) * 0.25);
            padding-bottom: calc(var(--global-margin) * 0.25);
            padding-left: calc(var(--global-margin) * 1.5);
            padding-right: calc(var(--global-margin) * 1.5);


        }
        .splash-side {
            padding-top: calc(var(--global-margin) * 2.5);
            padding-bottom: calc(var(--global-margin) * 2.5);
            background-color: var(--global-secondary-background);
            color: var(--global-inverse-color);

            h4 {
                color: var(--global-inverse-color);
            }
            &.splash-side--primary {
                background-color: var(--global-primary-background);
            }

            &.splash-side--opposite {
                text-align: right;

                [dir=rtl] & {
                    text-align: left;
                }
            }

            .button-wrapper {
                .uk-button {
                    min-width: 50%;
                }
            }
        }
    }
</style>
