<template>
    <div class="added-to-cart uk-container" >

        <div class="uk-card uk-card-default uk-margin-auto uk-width-expand">
            <div class="uk-card-header">
               <h3 class="uk-card-title">
                   <icon-text
                        class="uk-flex-bottom"
                        icon-class="uk-text-success"
                        text-class="uk-text-success"
                        icon="icon: check;ratio: 2.5">
                       Order complete!
                   </icon-text>

               </h3>
            </div>
            <div class="uk-card-body">
                <h4 class="uk-margin-remove">Your order is being handled right now. Kick back and relax, we are on it!</h4>
                <p class="uk-margin-remove-top">
                    You can check your order status in your personal area in My Vodafone, at any time.
                </p>
                <button-group class="uk-flex ">
                    <form-button type="secondary"
                    @click="$router.push({name: 'ecommerce-home'})">Continue Shopping</form-button>
                    <form-button type="primary"
                                 @click="$router.push({name: 'selfcare-dashboard'})"
                                 icon-end="chevron-right">My Vodafone</form-button>
                </button-group>
            </div>


        </div>

        <div class="upsale-container uk-container uk-container-large uk-margin-large-top uk-position-relative"
             uk-slider="">

            <h2>You may also be interested in these products</h2>

            <product-upsale-carousel></product-upsale-carousel>
        </div>



    </div>
</template>

<script>

    import ProductUpsale from "@/client/components/ecommerce/products/ProductUpsale.vue";
    import ProductUpsaleCarousel from "@/client/components/ecommerce/products/ProductUpsaleCarousel.vue";
    export default {
        props: {
            title: {
                type: [String, Boolean],
                default: false
            },
            background: {
                type: [String],
                default: 'ecommerce/headers/sample_header_2.jpg'
            }
        },
        components: {
            ProductUpsale, ProductUpsaleCarousel
        },
        data: function () {
            return {

            };
        },
        computed: {
            heroBackgroundStyle () {
                let url = utilities.requireAsset(this.background);
                return {
                    backgroundImage : "url('"+url+"')",

                };
            },

        },
        methods: {

        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">

    .hero {
        position: relative;
        height: 150px;

        .background {
            height: 100%;
            width: 100%;
            left: 50%;
            top: 50%;

            z-index: 0;
            overflow: hidden;


            .background-inner {

                height: calc(100% + 40px);
                width: calc(100% + 40px);
                left: calc(50% - 20px);
                top: calc(50% - 20px);

                background-size: cover;
                filter: blur(2px);
                -webkit-filter: blur(2px);
            }
        }

        .background-overlay {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,0.35);
            z-index: 0;
        }

        .content {
            position: relative;
            z-index: 1;
            height: 100%;
        }
    }



</style>
